import React, {FC, MouseEventHandler, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import {Button, ButtonProps, CircularProgress, Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import {LAMPORTS_PER_SOL} from "@solana/web3.js";

import {useAnchorWallet} from "@solana/wallet-adapter-react";

import whitelist from "./whitelist";

import {
    CandyMachine,
    awaitTransactionSignatureConfirmation,
    getCandyMachineState,
    mintOneToken,
    shortenAddress,
} from "./candy-machine";
import {Col, Container, Row, Stack} from "react-bootstrap";

import logo from './assets/BRBB_Logo_V4-03.png'
import twitter from './assets/Twitter_Pink_V1-01.png'
import discord from './assets/Discord_Pink_V2-01.png'
import scrapyardBackground from './assets/SCRAPYARD_PNG_V2.png'
import connectWalletButton from './assets/Connect-wallet-Button-V1.png'
import botImage from './assets/OG-Genesis-Robot-jetpack.jpg'
import mintButton from './assets/MINT-Button-V1.png'
import botBackground from './assets/Rarity_Card_BG_V01.png'
import divider from './assets/RARITY_DIVIDER.png'

import rarityRaw from './data/rarity-latest.json';

import ScrollTopButton from './components/ScrollTopButton'

import {useWalletDialog} from "@solana/wallet-adapter-material-ui";
// import {useWalletDialog} from "@solana/wallet-adapter-material-ui/src/useWalletDialog";
// import * as React from 'react';


import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {cachedDataVersionTag} from "v8";
import {makeStyles} from "@material-ui/core/styles";
// import InfoIcon from '@material-ui/core/Info';


const getOs = () => {
    const os = ['Windows', 'Linux', 'Mac']; // add your OS values
    return os.find(v => navigator.appVersion.indexOf(v) >= 0);
}


const os = getOs()

const topFontAdjustment = os != 'Windows' ? "45%" : "40%"
const topFontAdjustmentMint = os != 'Windows' ? "40.5%" : "42.5%"

const WalletDialogButton: FC<ButtonProps> = ({
                                                 children = 'Select Wallet',
                                                 color = 'primary',
                                                 variant = 'text',
                                                 onClick,
                                                 ...props
                                             }) => {
    const {setOpen} = useWalletDialog();

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            if (onClick) onClick(event);
            if (!event.defaultPrevented) setOpen(true);
        },
        [onClick, setOpen]
    );

    return (
        <Button className={'centered'} color={color} variant={variant} onClick={handleClick} {...props} style={{
            background: "none",
            color: "black",
            position: "absolute",
            whiteSpace: "nowrap",
            fontSize: "26px",
            top: topFontAdjustment,
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontFamily: "brokenRobotFont"
        }}>
            {children}
        </Button>
    );
};

// function StandardImageList() {
//     return (
//         <ImageList cols={3} rowHeight={164}>
//             {itemData.map((item) => (
//                 <ImageListItem key={item.img}>
//                     <img
//                         src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
//                         srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
//                         alt={item.title}
//                         loading="lazy"
//                     />
//                 </ImageListItem>
//             ))}
//         </ImageList>
//     );
// }
//
// const itemData = [
//     {
//         img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
//         title: 'Breakfast',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
//         title: 'Burger',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
//         title: 'Camera',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//         title: 'Coffee',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
//         title: 'Hats',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
//         title: 'Honey',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
//         title: 'Basketball',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//         title: 'Fern',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//         title: 'Mushrooms',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
//         title: 'Tomato basil',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//         title: 'Sea star',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
//         title: 'Bike',
//     },
// ];

// <div className="container2">
//   <img src={connectWalletButton} alt="" height={80} />
//   {/*<img src="img_snow_wide.jpg" alt="Snow" style="width:100%;">*/}
//   {/*<div className="bottom-left">Bottom Left</div>*/}
//   {/*<div className="top-left">Top Left</div>*/}
//   {/*<div className="top-right">Top Right</div>*/}
//   {/*<div className="bottom-right">Bottom Right</div>*/}
//   !wallet ? (
//   <ConnectButton>Connect Wallet</ConnectButton>
//   )
//
// </div>

const ConnectButton = styled(WalletDialogButton)`
  //background-color: black;
  //color: black;
  //font-family: brokenRobotFont;
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const TestContainer = styled.div``;

const MintButton = styled(Button)`background-color: aqua`; // add your styles here


const cardTextStyle = {
    // color: '#bbbbbc',
    fontFamily: "brokenRobotFont",
    fontSize: 25,
    // background: "#705feb",
    // margin: 7

}

const cardImageStyle = {
    position: "absolute"
}

export interface HomeProps {
    candyMachineId: anchor.web3.PublicKey;
    config: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    startDate: number;
    treasury: anchor.web3.PublicKey;
    txTimeout: number;
}

const borderRadius = 17

function MediaCard(imageSource: string, name: string, affinity: string) {
    return (
        <Card sx={{maxWidth: 250, m: 1}} style={{ borderRadius: borderRadius}}>
            <CardMedia
                component="img"
                height="250"
                width="250"
                image={botBackground}
                alt="green iguana"
            />
            <img src={imageSource} alt="" height={250} style={{ position: "absolute", marginTop: -250, borderTopLeftRadius: borderRadius, borderTopRightRadius: borderRadius}}/>


            {/*<Image*/}
            {/*    component="img"*/}
            {/*    height="250"*/}
            {/*    width="250"*/}
            {/*    image={imageSource}*/}
            {/*    alt="green iguana"*/}
            {/*/>*/}
            <CardContent style={{ textAlign: "center"}}>
                <Typography gutterBottom variant="h5" component="div" style={cardTextStyle}>
                    {name.toUpperCase()}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: "bolder"}}>
                    Space Affinity: {affinity}%
                </Typography>
            </CardContent>
        </Card>
    );
}

const topMarginButtonOs = os != 'Windows' ? 0 : -10
const paddingButtonOs = os != 'Windows' ? 1 : 11

const buttonStyle = {
    color: '#bbbbbc',
    fontFamily: "brokenRobotFont",
    fontSize: 40,
    background: "#705feb",
    margin: 7,
    border: 0,
    paddingTop: paddingButtonOs,
    paddingBottom: paddingButtonOs,
    paddingLeft: 11,
    paddingRight: 11,
    hover: {
        "&:hover": {
            backgroundColor: 'rgb(7, 177, 77, 0.42)'
        },
    }
    // transform: "translate(-50%, -50%)",
}

const buttonTextStyle = {
    // color: '#bbbbbc',
    fontFamily: "brokenRobotFont",
    fontSize: 40,
    marginTop: topMarginButtonOs,
}

const buttonGroupStyle = {
    textAlign: "center" as const,
    borderRadius: "0px",
    display: "block",
    hover: {
        "&:hover": {
            backgroundColor: 'rgb(7, 177, 77, 0.42)'
        },
    }
    // textAlign: "center"
}





// function ToggleButtonGroupControlled(inputs: Array<string>) {
//     const [value, setValue] = useState(new Array<string>());
//
//     /*
//      * The second argument that will be passed to
//      * `handleChange` from `ToggleButtonGroup`
//      * is the SyntheticEvent object, but we are
//      * not using it in this example so we will omit it.
//      */
//     const handleChange = (val: any) => {
//         console.log(val)
//         setValue(val);
//     }
//
//     return (
//         <ToggleButtonGroup type="checkbox" value={value} onChange={handleChange}>
//             { inputs.map(x => (
//                 <ToggleButton id={ "button-" + x } value={x}>
//                     { x }
//                 </ToggleButton>
//             ))}
//         </ToggleButtonGroup>
//     );
// }

const useStyles = makeStyles((theme) => ({
        buttonUi: {
            // zIndex: 2,
            // position: 'fixed',
            // bottom: '2vh',
            backgroundColor: 'blue',
            color: 'blue',
            "&:hover": {
                transition: '0.1s',
                backgroundColor: '#e1449d',
                color: 'black'
            },
            // [theme.breakpoints.up('xs')]: {
            //     right: '5%',
            //     backgroundColor: '#705feb',
            // },
            // [theme.breakpoints.up('lg')]: {
            //     right: '6.5%',
            // },
        }
    })
)


const RarityBrowser = (props: HomeProps) => {
    const [balance, setBalance] = useState<number>();
    const [isActive, setIsActive] = useState(false); // true when countdown completes
    const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
    const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

    const [isOnWhitelist, setIsOnWhitelist] = useState(false);

    const [itemsAvailable, setItemsAvailable] = useState(0);
    const [itemsRedeemed, setItemsRedeemed] = useState(0);
    const [itemsRemaining, setItemsRemaining] = useState(0);

    const [rarityData, setRarityData] = useState(new Array<any>())
    const [rarityTiers, setRarityTiers] = useState(new Array<string>())
    const [attributes, setAttributes] = useState(new Array<string>())


    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    const classes = useStyles()

    const [selectedRarity, setSelectedRarity] = useState(new Array<string>());
    const [selectedAttributes, setSelectedAttributes] = useState(new Array<string>());

    const [groupedData, setGroupedData] = useState(new Array<any>());
    const [filteredData, setFilteredData] = useState(new Array<any>());

    function ToggleButtonsMultiple(inputs: Array<string>, setData: any, getData: any) {
        const handleFormat = (
            event: React.MouseEvent<HTMLElement>,
            newFormats: string[],
        ) => {
            // console.log(newFormats)
            setData(newFormats);
        };

        return (
            <ToggleButtonGroup
                value={getData}
                onChange={handleFormat}
                aria-label="text formatting"
                style={ buttonGroupStyle }
                // orientation="vertical"
                // fullWidth={true}
            >
                { inputs.map(x => (
                    <ToggleButton value={x} aria-label={x} style={ buttonStyle } className={classes.buttonUi}>
                        <Typography style={ buttonTextStyle }>{ x }</Typography>
                    </ToggleButton>
                ))}

            </ToggleButtonGroup>
        );
    }


    const groupBy = (xs: any, key: string) => xs.reduce((acc: any, x: any) => Object.assign({}, acc, {
        [x[key]]: (acc[x[key]] || []).concat(x)
    }), {})

    useEffect(() => {
        const url = "https://pixel-blocks.s3.ap-southeast-2.amazonaws.com/rarity-latest.json"
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            // body: JSON.stringify({ title: 'React POST Request Example' })
        };
        // fetch(url, requestOptions)
        Promise.resolve<Record<string, any>[]>(rarityRaw)
            // .then(response => response.json())
            .then((x: Record<string, any>[]) => {
                // console.log(x)

                x = x.filter(y => !(y.name == 'Nothing' && y.attribute == 'Genesisrobot'))
                x.filter(y => y.attribute == 'Genesisrobot')[0].attribute = 'OG'

                // console.log(x.filter(y => y.attribute == 'Genesisrobot'))
                // console.log(x)

                // const rarityTiers = Object.fromEntries(Object.entries(groupBy(x, 'rarityTier')))
                // console.log(x)

                const rarityTiers = new Set(x.map(y => y.rarityTier))
                const attributes = new Set(x.map(y => y.attribute))

                setRarityTiers(Array.from(rarityTiers))
                setAttributes(Array.from(attributes))

                // console.log('rarity', rarityTiers)
                // console.log('attributes', attributes)

                setGroupedData(
                    Object.entries(groupBy(x, 'rarityTier')).map(rarityGroup => (
                        {name: rarityGroup[0], attributeGroup: Object.entries(groupBy(rarityGroup[1], "attribute")) }
                )))

                return x
            })
            .then(x => {
                setRarityData(x)
                setSelectedAttributes(['Body'])
            })
    }, [])

    useEffect(() => {

        // console.log(groupedData)

        let result: Array<any> = []

        if (!selectedRarity.length) {
            if (selectedAttributes.length) {
                result = groupedData.map(x => ({
                    name: x.name,
                    attributeGroup: x.attributeGroup.filter((attibute: string[]) => selectedAttributes.includes(attibute[0]))
                }))
            } else {
                result = groupedData
            }
        } else {
            if (selectedAttributes.length) {
                result = groupedData.filter((rarity: any) => selectedRarity.includes(rarity.name)).map(x => ({
                    name: x.name,
                    attributeGroup: x.attributeGroup.filter((attibute: string[]) => selectedAttributes.includes(attibute[0]))
                }))
            } else {
                result = groupedData.filter((rarity: any) => selectedRarity.includes(rarity.name))
            }
        }
        // console.log('filtered', result)

        setFilteredData(result)
    }, [selectedRarity, selectedAttributes])

    // const nonWhitelistAdditionalMillis = (60 * 1000)
    //
    // const [startDate, setStartDate] = useState(new Date(props.startDate + nonWhitelistAdditionalMillis));
    //
    const wallet = useAnchorWallet();
    // const [candyMachine, setCandyMachine] = useState<CandyMachine>();
    //
    // const refreshCandyMachineState = () => {
    //   (async () => {
    //     if (!wallet) return;
    //
    //     const {
    //       candyMachine,
    //       goLiveDate,
    //       itemsAvailable,
    //       itemsRemaining,
    //       itemsRedeemed,
    //     } = await getCandyMachineState(
    //         wallet as anchor.Wallet,
    //         props.candyMachineId,
    //         props.connection
    //     );
    //
    //     setItemsAvailable(itemsAvailable);
    //     setItemsRemaining(itemsRemaining);
    //     setItemsRedeemed(itemsRedeemed);
    //
    //     setIsSoldOut(itemsRemaining === 0);
    //
    //     const startTime = (whitelist.includes(wallet.publicKey.toBase58())) ? goLiveDate : new Date(goLiveDate.getTime() + nonWhitelistAdditionalMillis)
    //
    //     setStartDate(startTime);
    //     setCandyMachine(candyMachine);
    //   })();
    // };
    //
    // const onMint = async () => {
    //   try {
    //     setIsMinting(true);
    //     if (wallet && candyMachine?.program) {
    //       const mintTxId = await mintOneToken(
    //           candyMachine,
    //           props.config,
    //           wallet.publicKey,
    //           props.treasury
    //       );
    //
    //       const status = await awaitTransactionSignatureConfirmation(
    //           mintTxId,
    //           props.txTimeout,
    //           props.connection,
    //           "singleGossip",
    //           false
    //       );
    //
    //       if (!status?.err) {
    //         setAlertState({
    //           open: true,
    //           message: "Congratulations! Mint succeeded!",
    //           severity: "success",
    //         });
    //       } else {
    //         setAlertState({
    //           open: true,
    //           message: "Mint failed! Please try again!",
    //           severity: "error",
    //         });
    //       }
    //     }
    //   } catch (error: any) {
    //     // TODO: blech:
    //     let message = error.msg || "Minting failed! Please try again!";
    //     if (!error.msg) {
    //       if (error.message.indexOf("0x138")) {
    //       } else if (error.message.indexOf("0x137")) {
    //         message = `SOLD OUT!`;
    //       } else if (error.message.indexOf("0x135")) {
    //         message = `Insufficient funds to mint. Please fund your wallet.`;
    //       }
    //     } else {
    //       if (error.code === 311) {
    //         message = `SOLD OUT!`;
    //         setIsSoldOut(true);
    //       } else if (error.code === 312) {
    //         message = `Minting period hasn't started yet.`;
    //       }
    //     }
    //
    //     setAlertState({
    //       open: true,
    //       message,
    //       severity: "error",
    //     });
    //   } finally {
    //     if (wallet) {
    //       const balance = await props.connection.getBalance(wallet.publicKey);
    //       setBalance(balance / LAMPORTS_PER_SOL);
    //     }
    //     setIsMinting(false);
    //     refreshCandyMachineState();
    //   }
    // };
    //
    // useEffect(() => {
    //   (async () => {
    //     if (wallet) {
    //       const balance = await props.connection.getBalance(wallet.publicKey);
    //       setBalance(balance / LAMPORTS_PER_SOL);
    //     }
    //   })();
    // }, [wallet, props.connection]);
    //
    // useEffect(refreshCandyMachineState, [
    //   wallet,
    //   props.candyMachineId,
    //   props.connection,
    // ]);

    const spanStyle = {
        color: 'white',
        font: 'bold 24px/45px Helvetica, Sans-Serif',
        letterSpacing: '-1px',
        background: 'rgba(0, 0, 0, 0.4)',
        padding: '10px'
    }

    const bottomTextFont = 'Arial'

    return (
        <main>
            <Container>
                <br/>

                <Row className="justify-content-md-center">
                    <Col md="auto">
                    <div className="container2">
                        <img src={scrapyardBackground} alt="" height={"100%"} width={"100%"} />
                        {/*<img src="img_snow_wide.jpg" alt="Snow" style="width:100%;">*/}
                        {/*<div className="bottom-left">Bottom Left</div>*/}
                        {/*<div className="top-left">Top Left</div>*/}
                        {/*<div className="top-right">Top Right</div>*/}
                        {/*<div className="bottom-right">Bottom Right</div>*/}

                        {/*<div style={{*/}
                        {/*    background: "none",*/}
                        {/*    color: "black",*/}
                        {/*    position: "absolute",*/}
                        {/*    whiteSpace: "nowrap",*/}
                        {/*    fontSize: "70px",*/}
                        {/*    top: topFontAdjustmentMint,*/}
                        {/*    left: "50%",*/}
                        {/*    transform: "translate(-50%, -50%)",*/}
                        {/*    fontFamily: "brokenRobotFont",*/}
                        {/*    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"*/}
                        {/*}}>*/}
                        {/*    THE SCRAPYARD*/}
                        {/*</div>*/}

                    </div>
                    </Col>
                </Row>


                {/*<Stack direction="horizontal" gap={3}>*/}
                    {/*<div className={''}><a href='https://mint.brokenrobotburgerbar.com/'><img src={logo} height={60}*/}
                    {/*                                                                          alt="Logo"/></a></div>*/}


                    {/*<div className={'ms-auto'}><a href='https://twitter.com/BrokenRobotNFT'><img src={twitter}*/}
                    {/*                                                                             height={40}*/}
                    {/*                                                                             alt="twitter"/></a>*/}
                    {/*</div>*/}
                    {/*<div className={''}><a href='https://discord.com/invite/d2JYRuYdgh'><img src={discord} height={40}*/}
                    {/*                                                                         alt="discord"/></a></div>*/}
                    {/*<div className="container2">*/}
                    {/*    <img src={connectWalletButton} alt="" height={80}/>*/}
                    {/*    /!*<img src="img_snow_wide.jpg" alt="Snow" style="width:100%;">*!/*/}
                    {/*    /!*<div className="bottom-left">Bottom Left</div>*!/*/}
                    {/*    /!*<div className="top-left">Top Left</div>*!/*/}
                    {/*    /!*<div className="top-right">Top Right</div>*!/*/}
                    {/*    /!*<div className="bottom-right">Bottom Right</div>*!/*/}
                    {/*    {!wallet ? (*/}
                    {/*        <ConnectButton>Connect Wallet</ConnectButton>*/}
                    {/*    ) : <ConnectButton>Connected</ConnectButton>}*/}
                    {/*</div>*/}
                {/*</Stack>*/}

                <br/>

                <Row className="justify-content-md-center">
                    <Col md="auto">
                    {ToggleButtonsMultiple(attributes, setSelectedAttributes, selectedAttributes)}
                    </Col>
                </Row>



                <Row className="justify-content-md-center">
                    <Col md="auto">
                <img src={divider} alt="" width={"100%"} style={{ maxWidth: 875, marginTop: 25, marginBottom: 25}}/>
                    </Col>
                </Row>

                <Row className="justify-content-md-center">
                    <Col md="auto">
                {ToggleButtonsMultiple(rarityTiers, setSelectedRarity, selectedRarity)}
                    </Col>
                </Row>

                <br/>

                {/*{ rarityData.forEach(console.log)}*/}

                {filteredData.map(x => (
                    x.attributeGroup.map((attributeGroup: any) => (
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <br/>
                                <br/>
                                {/*<Row className="justify-content-md-center">*/}
                                    {/*<Col><h1>MINT</h1></Col>*/}
                                    {/*<Col md="auto">*/}
                                <Grid container justifyContent="center">
                                        <h3 style={{color: '#bbbbbc', fontFamily: "brokenRobotFont", letterSpacing: 1}}>{x.name} {attributeGroup[0]}</h3>
                                </Grid>
                                    {/*</Col>*/}
                                {/*</Row>*/}
                                <br/>

                                <Box>
                                    <Grid container spacing={0} justifyContent="center">
                                        { attributeGroup[1].map((attribute: any) => (
                                            MediaCard(`data:image/png;base64,${attribute.content}`, attribute.name, attribute.spaceAffinity)
                                        )) }
                                    </Grid>
                                </Box>
                            </Col>
                        </Row>


                    ))

                    // <img
                    //     src={`data:image/png;base64,${x.content}`}
                    //     // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    //     alt={x.name}
                    //     // key={x.name}
                    //     // loading="lazy"
                    // />

                ))}


                {/*{StandardImageList()}*/}
                {/*<Row className={'h-25'} style={{height: '100px'}}>*/}

                {/*</Row>*/}
                {/*<br/>*/}
                {/*<div className={''}><h1>ROBOT//</h1></div>*/}

                {/*<br/>*/}

                {/*<Row className="justify-content-md-center">*/}
                {/*    /!*<Col><h1>MINT</h1></Col>*!/*/}
                {/*    <Col md="auto"><h3 style={{color: '#bbbbbc', fontFamily: bottomTextFont}}>Failed A.I. experiments*/}
                {/*        dumped on Planet Earth,</h3></Col>*/}
                {/*</Row>*/}

                {/*<Row className="justify-content-md-center">*/}
                {/*    /!*<Col><h1>MINT</h1></Col>*!/*/}
                {/*    <Col md="auto"><h3 style={{color: '#bbbbbc', fontFamily: bottomTextFont}}>working at a burger bar*/}
                {/*        while secretly hatching a sneaky plan.</h3></Col>*/}
                {/*</Row>*/}

                {/*<br/>*/}

                {/*<Row className="justify-content-md-center">*/}
                {/*    <Col md="auto"><h3 style={{color: '#bbbbbc', fontFamily: bottomTextFont}}>10,000 Broken Robots will*/}
                {/*        be minted from salvaging scraps of metal</h3></Col>*/}
                {/*</Row>*/}
                {/*<Row className="justify-content-md-center">*/}
                {/*    <Col md="auto"><h3 style={{color: '#bbbbbc', fontFamily: bottomTextFont}}>out of The Wastelands on*/}
                {/*        the Solana blockchain.</h3></Col>*/}
                {/*</Row>*/}


                {/*<br/>*/}

            </Container>
            <ScrollTopButton showBelow={250} />

            {/*<Snackbar*/}
            {/*    open={alertState.open}*/}
            {/*    autoHideDuration={6000}*/}
            {/*    onClose={() => setAlertState({ ...alertState, open: false })}*/}
            {/*>*/}
            {/*  <Alert*/}
            {/*      onClose={() => setAlertState({ ...alertState, open: false })}*/}
            {/*      severity={alertState.severity}*/}
            {/*  >*/}
            {/*    {alertState.message}*/}
            {/*  </Alert>*/}
            {/*</Snackbar>*/}
        </main>
    );
};

interface AlertState {
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({days, hours, minutes, seconds, completed}: any) => {
    return (
        <CounterText>
            {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
        </CounterText>
    );
};

export default RarityBrowser;
